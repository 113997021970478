import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { TranslateModule } from '@ngx-translate/core';

import { AccessModule } from '../access';
import { WarnComponent } from '../feedback';
import { HasSettingDirective } from './has-setting.directive';
import { SettingsItemComponent } from './settings-item.component';
import { SettingsComponent } from './settings.component';
import { SettingRoutes } from './settings.routing';

@NgModule({
  declarations: [SettingsComponent, SettingsItemComponent, HasSettingDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    FormlyMatToggleModule,
    FormlyMatDatepickerModule,
    TranslateModule,

    SettingRoutes,

    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatSnackBarModule,

    WarnComponent,
    AccessModule,
  ],
  exports: [SettingsItemComponent, HasSettingDirective],
})
export class SettingsModule {}
