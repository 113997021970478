import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, combineLatest, firstValueFrom, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { objClone } from '@logic-suite/shared/utils/objClone';
import { objToString, titleCase } from '@logic-suite/shared/utils/stringUtils';

import { AccessService } from '../access';
import { AssetNode, AssetTreeService } from '../nav/asset-tree';
import { Setting, SettingItem } from './setting.model';
import { SettingsService } from './settings.service';

@Component({
  selector: 'app-settings-item',
  templateUrl: './settings-item.component.html',
  styleUrls: ['./settings-item.component.scss'],
})
export class SettingsItemComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() name!: string;
  @Input() child?: string;
  @Input() node: AssetNode | null = this.tree.getSelectedNode();
  @ViewChild('buttons') buttons!: ElementRef<HTMLElement>;

  setting?: Setting;
  options: SettingItem[] = [];
  changed = new BehaviorSubject(0);
  get childSetting(): Observable<Setting[]> {
    if (!this.name) {
      return of([]);
    }
    return this.changed.pipe(
      switchMap(_ => {
        if (this.child) {
          return this.service.getSettings(this.name, this.node).pipe(
            switchMap(settings => {
              if (!!settings[0].settings && Object.keys(settings[0].settings).some(s => s.startsWith('___'))) {
                return combineLatest(
                  Object.keys(settings[0].settings)
                    .filter(s => s.startsWith('___'))
                    .map(s => this.name + '-' + s.substring('___'.length).toLowerCase())
                    .map(s => this.service.getSettings(s, this.node)),
                ).pipe(
                  map(results => [...objClone(results)]),
                  map(results =>
                    results.map(val => {
                      const name = val.name.substring(this.name.length + 1).trim();
                      Object.keys(val.settings).forEach(k => {
                        val.settings[k].label = titleCase(
                          this.translate.instant(name) + ' ' + this.translate.instant(k),
                        );
                      });
                      return val;
                    }),
                  ),
                );
              }
              return of([]);
            }),
          );
        } else if (!this.child) {
          return of([]);
        }
        return this.service.getSettings(this.name + '-' + this.child, this.node);
      }),
    );
  }

  get isOverride() {
    return this.setting ? Object.entries(this.setting.settings).some(([, value]) => !!value.isOverride) : false;
  }

  get canUpdate() {
    return this.access.hasFeature('settings', 'U') || this.access.isAdministrator() || this.access.isNoovaEmployee();
  }

  get hasFields() {
    return this.options.some(f => f.fields.length > 0);
  }

  form = new UntypedFormGroup({});
  subscriptions: Subscription[] = [];
  loadDataSubscription$!: Subscription;

  constructor(
    private service: SettingsService,
    private translate: TranslateService,
    private snack: MatSnackBar,
    private access: AccessService,
    private tree: AssetTreeService,
    private el: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const buttonContainer = this.el.nativeElement
        .closest('.mat-mdc-tab-group')
        ?.parentElement.querySelector('.mat-dialog-actions');
      if (buttonContainer) {
        const footer = this.buttons.nativeElement;
        buttonContainer.appendChild(footer);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.child?.currentValue) {
      this.changed.next(this.changed.value + 1);
    }
    if (changes.node?.currentValue || changes.name?.currentValue) {
      this.loadSettings();
    }
  }

  loadSettings() {
    if (this.loadDataSubscription$) {
      this.loadDataSubscription$.unsubscribe();
    }
    this.loadDataSubscription$ = combineLatest([
      this.service.getSettings(this.name, this.node),
      this.childSetting,
    ]).subscribe(([settings, childSettings]) => {
      const complete = settings.concat(childSettings);
      this.options = this.service.buildForm(complete, !this.canUpdate);
      this.setting = complete[0];
    });
  }

  @HostListener('keydown.enter')
  save() {
    this._save(this.form.getRawValue());
  }

  reset() {
    this._save(null);
  }

  private async _save(value: Setting | null) {
    try {
      const res = await firstValueFrom(
        this.service.save(
          { [this.name + (this.child ? '-' + this.child : '')]: value },
          this.node ? this.node : undefined,
        ),
      );
      this.form.markAsPristine();
    } catch (err: any) {
      const msg = typeof err.error === 'object' ? objToString(err.error) : err.error;
      this.snack.open(msg, 'Ok', { duration: 5000 });
    }
  }

  ngOnDestroy() {
    this.loadDataSubscription$?.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe());
    this.buttons?.nativeElement?.remove();
  }
}
