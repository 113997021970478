<header>
  <mat-icon>settings</mat-icon>
  <h1>{{ 'Settings' | translate }}</h1>
  <a [routerLink]="[returnRoute()]" [queryParams]="returnRouteParams()" mat-icon-button><mat-icon>close</mat-icon></a>
</header>

<mat-accordion>
  @for (item of settings; track item.widget) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ item.name | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-settings-item [name]="item.widget" [node]="null"></app-settings-item>
      </ng-template>
    </mat-expansion-panel>
  }
</mat-accordion>

<app-warn type="announcement">
  <span>{{ 'These are company wide settings' | translate }}.</span>
  <span>
    @if (canUpdate) {
      {{ 'By changing values here, you are changing the defaults for your company' | translate }}.
    } @else {
      {{ 'Only company administrators can change the company defaults' | translate }}.
    }
  </span>
</app-warn>
