import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { SettingsService } from './settings.service';

@Directive({
  selector: '[appHasSetting]',
})
export class HasSettingDirective implements OnInit, OnDestroy {
  name!: string;
  @Input() set appHasSetting(val: string) {
    this.name = val;
  }

  subscriptions: Subscription[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private service: SettingsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.service
        .getSettings(this.name)
        .pipe(first())
        .subscribe(settings => {
          const hasSetting = settings.some(s => Object.keys(s.values).length > 0);
          // Either allow rendering of this element or hide it based on results above
          if (hasSetting === true) {
            // Widget has settings. Show element
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            // No settings, element is removed
            this.viewContainer.clear();
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
