import { AfterViewInit, Component, OnDestroy, QueryList, ViewChildren, inject, signal } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { deepMerge } from '@logic-suite/shared/utils/deepMerge';

import { AccessService } from '../access';
import { Setting } from './setting.model';
import { SettingsService } from './settings.service';

/**
 * Component for showing global settings
 */
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements AfterViewInit, OnDestroy {
  private readonly service = inject(SettingsService);
  private readonly route = inject(ActivatedRoute);
  private readonly access = inject(AccessService);

  settings: Setting[] = [];
  subscriptions: Subscription[] = [];
  @ViewChildren(MatExpansionPanel) panels!: QueryList<MatExpansionPanel>;
  returnRoute = signal('/');
  returnRouteParams = signal<Record<string, string>>({});

  get canUpdate() {
    return this.access.hasFeature('settings', 'U') || this.access.isAdministrator() || this.access.isNoovaEmployee();
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.route.queryParams
        .pipe(
          switchMap(() => {
            // Never return to where we already are.
            const params = this.access.getQueryParams();
            if (params.returnRoute) {
              const decodedRoute = decodeURIComponent(params.returnRoute);
              const path = decodedRoute.split('?')[0];
              const query = this.access.unpackQuery(decodedRoute.split('?')[1] ?? '');
              if (!path.startsWith('/settings')) this.returnRoute.set(path);
              this.returnRouteParams.set(query);
            }
            return this.service.getSettings(undefined, undefined);
          }),
          map(settings => settings?.filter(s => Object.keys(s.settings).length > 0)),
        )
        .subscribe(settings => {
          const panelIdx = Array.from(this.panels).findIndex(p => p.expanded);
          const s = settings?.sort((a, b) => (b.widget == 'global' || a.name > b.name ? 1 : -1));
          this.settings = deepMerge(this.settings, s) as Setting[];
          setTimeout(() => {
            Array.from(this.panels)[panelIdx]?.open();
          });
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
