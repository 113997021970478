@for (item of options; track $index) {
  @if (item.fields.length > 0) {
    <formly-form [model]="item.model" [fields]="item.fields" [form]="form"></formly-form>
  } @else {
    <app-warn>{{ 'This widget does not contain settings' | translate }}</app-warn>
  }
}
@if (canUpdate && hasFields) {
  <footer #buttons>
    <button type="submit" mat-raised-button color="primary" [disabled]="!form.dirty" (click)="save()">
      {{ 'Save' | translate }}
    </button>
    @if (isOverride) {
      <button type="button" mat-raised-button (click)="reset()">{{ 'Reset' | translate }}</button>
    }
  </footer>
}
