import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@logic-suite/shared/auth';

import { HasAppGuard, CustomerGuard } from '../access';
import { SettingsComponent } from './settings.component';

export const routes: Routes = [
  { path: 'settings', component: SettingsComponent, canLoad: [AuthGuard], canActivate: [CustomerGuard, HasAppGuard] },
];

export const SettingRoutes = RouterModule.forChild(routes);
